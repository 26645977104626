var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { value: _vm.open, "max-width": "330" },
          on: { "click:outside": _vm.reset },
        },
        [
          _c("div", { staticClass: "bgWalletBlock pa-7" }, [
            _vm.step === 1
              ? _c(
                  "div",
                  { staticClass: "full-width" },
                  [
                    _c("div", { staticClass: "mew-heading-2 mb-10" }, [
                      _vm._v("Add custom token"),
                    ]),
                    _c("mew-input", {
                      attrs: {
                        value: _vm.contractAddress,
                        placeholder: "Contract address",
                      },
                      on: { input: _vm.setContractAddress },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.step === 2
              ? _c(
                  "div",
                  { staticClass: "full-width" },
                  _vm._l(_vm.tokenDataToDisplay, function (tkn, idx) {
                    return _c(
                      "div",
                      {
                        key: idx,
                        staticClass: "mb-9",
                        class: [
                          _vm.tokenDataToDisplay[idx + 1] &&
                          !_vm.isIcon(_vm.tokenDataToDisplay[idx + 1].name) &&
                          !_vm.tokenDataToDisplay[idx + 1].value
                            ? "mb-1"
                            : "mb-9",
                        ],
                        attrs: { "no-gutters": "" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "textLight--text mew-body font-weight-bold align-self-center",
                          },
                          [_vm._v(" " + _vm._s(tkn.name) + " ")]
                        ),
                        _c(
                          "div",
                          { staticClass: "textDark--text" },
                          [
                            !_vm.isIcon(tkn.name) &&
                            !_vm.isContractAddress(tkn.name) &&
                            !_vm.isSymbol(tkn.name) &&
                            tkn.value
                              ? _c("span", [
                                  _vm._v(" " + _vm._s(tkn.value) + " "),
                                ])
                              : _vm._e(),
                            _vm.isContractAddress(tkn.name)
                              ? _c("mew-transform-hash", {
                                  attrs: {
                                    "justify-start": "",
                                    hash: _vm.getXDCAddress(tkn.value),
                                  },
                                })
                              : _vm._e(),
                            _vm.isIcon(tkn.name) && tkn.value
                              ? _c("img", {
                                  attrs: {
                                    height: "24px",
                                    width: "23.5px",
                                    src: tkn.value,
                                    alt: "token icon",
                                  },
                                })
                              : _vm._e(),
                            _vm.isIcon(tkn.name) && !tkn.value
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "token-placeholder mew-caption d-flex align-center justify-center",
                                  },
                                  [_vm._v(" NA ")]
                                )
                              : _vm._e(),
                            (!_vm.isIcon(tkn.name) && !tkn.value) ||
                            _vm.isSymbol(tkn.name)
                              ? _c("mew-input", {
                                  class: _vm.isSymbol(tkn.name)
                                    ? "mt-0 mb-n4"
                                    : "mb-n8",
                                  attrs: {
                                    id: idx,
                                    "error-messages":
                                      idx === 3
                                        ? _vm.symbolLengthTooLong
                                        : _vm.nameLengthTooLong,
                                    value: tkn.value,
                                    placeholder: _vm.getPlaceholder(tkn.name),
                                  },
                                  on: { input: _vm.setInputValue },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "d-flex align-center mt-5" },
              [
                _c("mew-button", {
                  staticClass: "flex-grow-1 mr-1",
                  staticStyle: { "flex-basis": "0" },
                  attrs: {
                    "btn-size": "large",
                    "btn-style": "outline",
                    title: "Cancel",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.reset.apply(null, arguments)
                    },
                  },
                }),
                _c("mew-button", {
                  staticClass: "flex-grow-1 ml-1",
                  staticStyle: { "flex-basis": "0" },
                  attrs: {
                    "btn-size": "large",
                    loading: _vm.loading,
                    disabled: _vm.isDisabled,
                    title: _vm.step === 1 ? "Next" : "Add Token",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.next.apply(null, arguments)
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }